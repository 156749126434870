<template>
  <f7-page name="list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>Tool List</f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
         :value="keyword" 
         @input="keyword = $event.target.value"
         @keypress.native="searchTask($event)"
         @change="refreshTaskList($event)"
      ></f7-searchbar>
    </f7-navbar>

    <div class="card">
      <f7-list>
        <f7-list-item
          v-for="tool in tools"
          :key="'T' + tool.id"
          :link="'/tool/' + tool.id"
          :title="tool.name"
          no-chevron
        >
          <i
            class="icon icon-forward"
            :class="removeSpace(tool._location.value) + '_color'"
          ></i>
        </f7-list-item>
        <f7-list-item
          v-for="device in devices"
          :key="'D' + device.id"
          :link="'/device/' + device.id"
          :title="device.name"
          no-chevron
        >
          <i
            class="icon icon-forward"
            :class="removeSpace(device._location.value) + '_color'"
          ></i>
        </f7-list-item>
      </f7-list>

      <div class="data-table-footer"></div>
    </div>
    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>
      <f7-link
        ><i class="icon f7-icons color-blue">person_2_square_stack</i></f7-link
      >
      <f7-link
        ><i class="icon f7-icons color-blue">slider_horizontal_3</i></f7-link
      >
      <f7-link><i class="icon f7-icons color-blue">clear</i></f7-link>
      <f7-link href="/addtool/"
        ><i class="icon f7-icons color-blue">plus</i></f7-link
      >
    </f7-toolbar>
  </f7-page>
</template>
  <script>
export default {
  components: {},
  data() {
    return {
      search: false,
      keyword: "",
      limit: 20,
      tools: "",
      devices: "",
      token: "",
      shop: "",
      first_name: "",
      last_name: "",
      showall: false,
    };
  },
  mounted() {},

  async created() {
    var userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    userStore
      .getItem("first_name")
      .then((value) => (this.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    userStore
      .getItem("last_name")
      .then((value) => (this.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    var credStore = this.$vlf.createInstance({
      storeName: "cred",
    });
    await credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    const data = {
      keyword: this.keyword,
      limit: this.limit,
      shop: this.shop,
      sales_rep: this.first_name + " " + this.last_name,
      showall: this.showall,
    };
    const token = this.token;
    console.log(data);
    console.log(this.token);
    const url = apiUrl + "/api/tools/toollist";
    console.log(url);
    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.tools = response.data.tools;
        this.devices = response.data.devices;
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    loadTools() {
      const data = {
        keyword: this.keyword,
        limit: this.limit,
        shop: this.shop,
        sales_rep: this.first_name + " " + this.last_name,
        showall: this.showall,
      };
      console.log(data);
      const url = apiUrl + "/api/tools/toollist";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.tools = response.data.tools;
          this.devices = response.data.devices;
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    searchTask(event) {
      // if (event.keyCode == 13) {
      // event.preventDefault();
      this.search = true;
      this.loadTools();
      // }
    },
    testToggle() {
      this.showall = !this.showall;
      this.loadTools();
    },
    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
  },
};
</script>
  <style>
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
</style>