// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';


//Import Axios
import axios from 'axios';
 window.axios = axios;

import {polyfill} from "mobile-drag-drop";

// optional import of scroll behaviour
import {scrollBehaviourDragImageTranslateOverride} from "mobile-drag-drop/scroll-behaviour";

// options are optional ;)
// polyfill({
//     // use this to make use of the scroll behaviour
//     dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
// });

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// import localForage from 'localforage';
import Vlf from 'vlf';
import localforage from 'localforage';
Vue.use(Vlf, localforage);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

console.log ( process.env.NODE_ENV );

if( process.env.NODE_ENV  == 'development'){
// window.apiUrl = 'http://10.10.10.116/servco';
window.apiUrl = 'http://192.168.2.124/servco_upgrade';
}else if(process.env.NODE_ENV  == 'production'){
  window.apiUrl = 'https://app.truckserviceco.com';
}
var userStore = localforage.createInstance({
  storeName: "user",
});
 userStore.getItem('token')
 .then((value) => (window.TOKEN = value))
 .catch(function (err) {
  console.log(err);
});

var accessStore = localforage.createInstance({
  storeName: "access",
});
accessStore.getItem('access')
.then((value) => (window.ACCESS = value))
.catch(function (err) {
  // This code runs if there were any errors
  console.log(err);
});

new Vue({
  el: '#app',
  mode:'history',
  render: (h) => h(App),
  serviceWorker: {
   path: '../service-worker.js',
   scope: '/',
 },

  // Register App Component
  components: {
    app: App,

  },
});
