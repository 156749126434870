<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen>
  <f7-navbar title="Login" back-link="Back"></f7-navbar>
    <f7-login-screen-title>Servco App</f7-login-screen-title>
    <f7-block v-if="loginerror == true" bg-color="red" text-color="white" class="text-align-center" >
      <p >The password or username did not match. Try again!</p>
    </f7-block>
    <f7-list form id="login">
      <f7-list-input
        label="Username"
        type="text"
        placeholder="Your username"
        :value="username"
        @input="username = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        label="Password"
        type="password"
        placeholder="Your password"
        :value="password"
        @keypress.native.enter ="signIn"
        @input="password = $event.target.value"
      ></f7-list-input>
    </f7-list>

    <f7-list>
      <f7-list-button @click="signIn">Sign In</f7-list-button>
      <f7-list-button href="/reset/">Forgot Password</f7-list-button>
      <!-- <f7-block-footer> Some text about login information.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</f7-block-footer> -->
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        login:'Test',
        loginerror: false
      };
    },

 
    methods: {


      signIn() {
        const self = this;
        const app = self.$f7;
        const router = self.$f7router;
       const un = self.username;
       const pw = self.password;
       const dt =  {username: un ,password: pw };
console.log(dt)

        const url = apiUrl + "/api/users/token";
        // const url = "https://api.truckserviceco.com/login/index.php";
    const credStore =  this.$vlf.createInstance({
           storeName: 'cred'
       });
       const userStore =  this.$vlf.createInstance({
              storeName: 'user'
          });
      const accessStore =  this.$vlf.createInstance({
              storeName: 'access'
          });
    accessStore.setItem('access','');
        try {
  axios.post(url, dt, {headers: {
   'Accept': 'application/json',
   'Content-Type':'application/json',
   }
// }).then((response) => console.log(response))access
}).then(response =>this.login = response.data)
                  .then(function(login){
                    console.log(login)
                  credStore.setItem('uid',login.data.id);
                  credStore.setItem('token',login.data.token);
                  userStore.setItem('id',login.user.id);
                  userStore.setItem('first_name',login.user.first_name);
                  userStore.setItem('last_name',login.user.last_name);
                  userStore.setItem('shop',login.user.shop);
                  userStore.setItem('email',login.user.email);
                  userStore.setItem('secure',login.user.secure);
                  userStore.setItem('branch',login.user.branch);
                  userStore.setItem('shopName',login.shopName);
                  userStore.setItem('token',login.data.token);
                  accessStore.setItem('access',login.data.access);
                  router.navigate('/');
      
                  })
                  .catch(function(error){

                    self.loginerror = true;
             
                  console.log(error);
               
              
                  });


   } catch (error) {
  
     console.error(`Request error: ${error.message}`);
      
   }



      },
    },


  };
</script>
