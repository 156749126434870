<template>
  <f7-page name="tool">
    <f7-navbar title="Tool View" back-link="Back"></f7-navbar>

    <f7-row class="details">
      <f7-badge
        class="badge shop_bold"
        :class="removeSpace(tool._current_location.value)"
        >@{{ tool._current_location.value }}</f7-badge
      >

      <f7-badge
        class="badge"
        :class="removeSpace(tool.category.name)"
        color="blue"
        >{{ tool.category.name }}</f7-badge
      >
    </f7-row>
    <!-- <f7-list-item :link="'/edittool/'+ tool.id" title="Edit Tool"></f7-list-item> -->
    <f7-list media-list dividers-ios strong-ios outline-ios>
      <f7-list-item title="Tool Name" :subtitle="tool.name"> </f7-list-item>
      <f7-list-item
        v-if="tool.reference"
        title="Reference No"
        :subtitle="tool.reference"
      >
      </f7-list-item>
      <f7-list-item title="Part Number" :subtitle="tool.partNumber">
      </f7-list-item>
      <f7-list-item title="Description" :text="tool.description">
      </f7-list-item>
      <f7-list-item title="Storage Location" :text="tool.storage_location">
      </f7-list-item>
      <f7-list-item title="Home Shop">
        <f7-badge
          class="badge"
          :class="removeSpace(tool._location.value)"
          color="green"
          >{{ tool._location.value }}</f7-badge
        >
      </f7-list-item>
      <f7-list-item>
        <f7-button
          v-if="mShop == false && transfer == null"
          color="green"
          raised
          fill
          @click="toolRequest('transfer')"
          >Request Transfer</f7-button
        >
        <f7-button
          v-if="
            tool.location != tool.current_location &&
            mShop == true &&
            transfer == null
          "
          color="red"
          raised
          fill
          @click="toolRequest('return')"
          >Return Tool</f7-button
        >
        <br />
        <f7-button v-if="mShop == true" color="green" raised outline
          >Item Here</f7-button
        >
        <div v-if="transfer == null">
          <select
            name="branch_options"
            ref="branch_options"
            style="text-align: center"
            class="bg-color-blue text-color-white border-color-gray"
            @change="closeList()"
          >
            <option>Send Tool To...</option>
            <option
              v-for="branch in branch_options"
              :id="branch.id"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.value }}
            </option>
          </select>
        </div>
        <br />
        <f7-button
          v-if="mTransfer == true || mReturn == true"
          color="red"
          raised
          fill
          @click="toolRequest('cancel')"
          >Cancel</f7-button
        >
      </f7-list-item>

      <f7-list-item v-if="mTransfer == true" class="transfer_alert">
        {{ transfer.user.full_name }} has requested this item be transfered from
        {{ tool._current_location.value }} to {{ transfer._location.value }}.
      </f7-list-item>
      <f7-list-item v-if="mReturn == true" class="transfer_alert">
        {{ transfer.user.full_name }} has requested this item be transfered from
        {{ tool._current_location.value }} to {{ transfer._location.value }}.
      </f7-list-item>
    </f7-list>

    <div class="upper-area">
      <f7-list accordion-list inset>
        <f7-list-item
          id="note"
          accordion-item
          title="Notes"
          :badge="noteCount"
          badge-color="red"
        >
          <f7-accordion-content id="note">
            <Notes
              :model="model"
              :notes="notes"
              :userid="user.id"
              :entid="tool.id"
              @notesCount="setNoteCount"
              @updateNotes="setNotes"
            />
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item
          accordion-item
          title="Pictures"
          :badge="picCount"
          badge-color="red"
        >
          <f7-accordion-content>
            <f7-swiper
              v-if="pics.length > 0"
              pagination
              navigation
              scrollbar
              :loop="false"
              :speed="500"
              :slidesPerView="1"
              :spaceBetween="20"
            >
              <f7-swiper-slide
                v-for="(pic, index) in pics"
                :key="index"
                :title="pic"
                @taphold.native="deletePic(pic)"
              >
                <figure>
                  <figcaption></figcaption>
                  <img
                    class="tapHold"
                    :src="domain + fileUrl + pic"
                    @dblclick="deletePic(pic)"
                  />
                </figure>
              </f7-swiper-slide>
              <f7-swiper-slide
                ><div v-on:click="opencam">
                  <div>Add image...</div>
                  <i class="icon f7-icons color-blue slider_add">camera</i>
                </div></f7-swiper-slide
              >
            </f7-swiper>

            <f7-block v-else-if="pics.length == 0" class="upload_icon">
              <div v-on:click="opencam">
                <div>Add image...</div>
                <i class="icon f7-icons color-blue slider_add">camera</i>
              </div>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
        <div class="space"></div>
      </f7-list>
    </div>

    <f7-popup id="camera" class="cam-popup">
      <f7-page>
        <f7-navbar title="Upload Pictures">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <Camera :model="model" :item="tool" :uid="user.id" @picList="setPics" />
      </f7-page>
    </f7-popup>

    <f7-toolbar bottom class="bottom-toolbar">
      <f7-link href="/"><i class="icon f7-icons color-blue">house</i></f7-link>

      <!-- <f7-link v-on:click="upload_file"
          ><i class="icon f7-icons color-blue">square_arrow_up</i></f7-link
        > -->
      <f7-link v-on:click="toggleNote"
        ><i class="icon f7-icons color-blue">chat_bubble_text</i></f7-link
      >
      <f7-link popup-open=".cam-popup"
        ><i class="icon f7-icons color-blue">camera</i></f7-link
      >
    </f7-toolbar>
  </f7-page>
</template>
<script>
import Notes from "../../components/Notes.vue";
import Camera from "../../components/CameraComp.vue";

export default {
  components: {
    Notes,
    Camera,
  },
  data() {
    return {
      componentKey: 0,
      model: "Tools",
      isEditing: false,
      id: "",
      addTimes: false,
      shop: "cov",
      email: "",
      tz: "-05:00",
      tool: {
        _location: {
          value: "",
        },
        _current_location: {
          value: "",
        },
        category: {
          name: "",
        },
      },
      notes: [],
      pics: "",
      picCount: "",
      noteCount: "",
      transfer: null,
      mShop: false,
      mTransfer: false,
      mReturn: false,
      branch_options: {},
      myShopId: 0,
      user: {
        first_name: "",
        last_name: "",
        id: 0,
        shop: "",
        token: "",
        shopName: "",
      },
      domain: apiUrl,
      fileUrl: "/webroot/files/Tools/pics/",
    };
  },

  mounted() {
    this.id = this.$f7route.params.id;
    this.shop = this.$f7route.params.shop;

    this.domain = apiUrl;
  },

  async created() {
    const userStore = this.$vlf.createInstance({
      storeName: "user",
    });
    await userStore
      .getItem("first_name")
      .then((value) => (this.user.first_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("last_name")
      .then((value) => (this.user.last_name = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("id")
      .then((value) => (this.user.id = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shop")
      .then((value) => (this.user.shop = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("token")
      .then((value) => (this.user.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    await userStore
      .getItem("shopName")
      .then((value) => (this.user.shopName = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    const url = apiUrl + "/api/tools/view";

    const data = {
      id: this.$f7route.params.id,
      requestingLocation: this.user.shop,
    };

    await axios
      .post(url, data, {
        origin: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        this.notes = response.data.tool.notes;
        this.tool = response.data.tool;
        this.noteCount = this.setBadge(response.data.tool.notes.length);
        this.pics = response.data.pics;
        this.picCount = this.setBadge(response.data.pics.length);
        this.transfer = response.data.transfer;
        this.transfer_status = this.setTransfer();
        this.branch_options = response.data.branch_options;
        this.myShopId = response.data.myShopId;
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(this.transfer);
    console.log(this.user);
  },

  methods: {
    reloadTool() {
      const url = apiUrl + "/api/tools/view";
      const data = {
        id: this.tool.id,
        requestingLocation: this.myShopId,
      };
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.notes = response.data.tool.notes;
          this.tool = response.data.tool;
          this.noteCount = this.setBadge(response.data.tool.notes.length);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
          this.transfer = response.data.transfer;
          this.transfer_status = this.setTransfer();
          this.myShopId = response.data.myShopId;
          this.branch_options = response.data.branch_options;
          console.log(this.transfer);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    opencam() {
      const app = this.$f7;
      app.popup.open("#camera");
    },
    setTransfer() {
      this.mTransfer = this.myTransfer();
      if (this.mTransfer == true) {
        this.mReturn = false;
      } else {
        this.mReturn = this.myReturn();
      }
      this.mShop = this.myShop();
    },
    myTransfer() {
      if (
        this.transfer != null &&
        this.user.shopName === this.transfer._location.value
      ) {
        return true;
      } else {
        return false;
      }
    },
    myReturn() {
      if (
        this.transfer != null &&
        (this.user.shopName === this.tool._current_location.value ||
          this.user.id === this.transfer.user_id)
      ) {
        return true;
      } else {
        return false;
      }
    },
    myShop() {
      if (this.tool._current_location.value === this.user.shopName) {
        return true;
      } else {
        return false;
      }
    },
    toggleNote() {
      alert("add note");
    },
    setPics(pics) {
      this.pics = pics;
      this.picCount = this.setBadge(pics.length);
    },
    setNoteCount(noteCount) {
      console.log(noteCount);
      this.noteCount = this.setBadge(noteCount);
    },
    setNotes(notes) {
      console.log(notes);
      this.notes = notes;
    },
    setBadge(num) {
      if (num > 0) {
        return num;
      } else {
        return "";
      }
    },
    formatTitle(val) {
      val = val.split("_");
      return val[2];
    },
    deletePic(pic) {
      var del = confirm("Delete this image?");

      if (del == true) {
        this.sendDeleteRequest(pic);
      }
    },

    toolRequest(request) {
      var api = "";
      if (request === "transfer" || request === "return") {
        let special = prompt("Special Instructions:");
        api = "/api/tools/requesttransfer";
        var data = {
          user_id: this.user.id,
          id: this.tool.id,
          reference: this.tool.reference,
          current_branch: this.tool._current_location.value,
          home_branch: this.tool.location,
          special: special,
          request: request,
          requestingLocation: this.myShopId,
        };
      } else if (request == "cancel") {
        api = "/api/tools/canceltransfer";
        var data = {
          user_id: this.user.id,
          id: this.transfer.id,
        };
      } else {
        alert("Something went wrong. Try your request again.");
        return;
      }
      const url = this.domain + api;

      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          this.reloadTool();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendDeleteRequest(filename) {
      const data = {
        filename: filename,
      };
      console.log(data);
      const url = apiUrl + "/api/tools/deletedoc";

      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          console.log(response);
          this.pics = response.data.pics;
          this.picCount = this.setBadge(response.data.pics.length);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closeList() {
      if (
        confirm(
          "Are you sure you want to send this tool to " +
            this.$refs.branch_options.value +
            "?"
        )
      ) {
        this.myShopId = this.$refs.branch_options.value;
        this.toolRequest("transfer");
      }
    },
  },
};
</script>
<style>
.view {
  border-color: transparent;
  background-color: initial;
  color: initial;
}
.tool_img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  float: left;
}
.transfer_alert {
  background-color: yellow;
}
.space {
  padding-bottom: 100px;
}
</style>